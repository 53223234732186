import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

export function WordCreate() {
  return (
    <Create>
      <SimpleForm>
        <TextInput source="word" />
        <BooleanInput source="isCatalan" />
        <p><em>Note: you need to save the word before adding translation(s) as a next step.</em></p>
      </SimpleForm>
    </Create>
  );
};


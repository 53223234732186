import { Edit } from 'react-admin';

import { TranslationForm, TranslationFormProps } from './TranslationForm';

export function TranslationEdit(props: TranslationFormProps) {
  return (
    <Edit redirect={false}>
      <TranslationForm {...props} />
    </Edit>
  );
}
import { useEffect, useState } from 'react';
import {
  AutocompleteArrayInput,
  ArrayInput,
  ReferenceField,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  useRecordContext,
  BooleanInput,
} from 'react-admin';
import { Typography } from '@mui/material';
import { Translation, TranslationType } from 'catalan-dictionary-common';

import { APP_ROOT_URI } from '../conf';

export interface TranslationFormProps {
}
interface Choice {
  id: string,
  name: string,
}
export function TranslationForm(props: TranslationFormProps) {
  const [categories, setCategories] = useState<Choice[]>([]);

  // load all existing categories from DB
  useEffect(() => {
    (async () => {
      await fetch(`${APP_ROOT_URI}/categories`)
        .then((resp) => {
          return resp.json();
        })
        .then((categoriesFromApi: string[]) => {
          setCategories(categoriesFromApi.map((category) => {
            return {
              id: category,
              name: category,
            };
          }));
        });
    })();
  }, []);

  const translation: Translation = useRecordContext();

  const gender = (translation && translation.type === TranslationType.Nouns) ?
    <SelectInput source="gender" fullWidth choices={[
      { id: 'f', name: 'Feminine' },
      { id: 'm', name: 'Masculine' },
      { id: 'mf', name: 'Feminine or Masculine' },
    ]} /> : <></>;

  // TODO: support create new
  const category = (translation && translation.type === TranslationType.Nouns) ?
    <AutocompleteArrayInput source="categories" fullWidth choices={categories} /> : <></>;

  const examplesContent = (
    // TODO: use classes, not inline CSS
    <div style={{ width: '100%', marginBottom: '20px' }}>
      <h2>Examples</h2>
      <sub>
        <em>
          Simple translated examples in English and Catalan of the word in everyday use.
        </em>
      </sub>
      <ArrayInput source="examples" fullWidth>
        <SimpleFormIterator disableReordering>
          <TextInput source="cat" label="Catalan Translation" fullWidth validate={[required()]} />
          <TextInput source="eng" label="English Translation" fullWidth validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput>
    </div>
  );

  const type = (() => {
    // if we are editing, you cannot change the type
    if (translation && translation.index != null) {
      return <TextInput disabled source="type" fullWidth />;
    }
    const translationTypeChoices = Object.entries(TranslationType).map(([label, id]) => {
      return {id, name: label};
    });
    return <SelectInput source="type" fullWidth choices={translationTypeChoices} validate={[required()]} />;
  })();

  const IsCatalanField = ({ source }: { source: string }) => {
    const record = useRecordContext();
    return (<span>({(record && record[source]) ? 'Catalan' : 'English'})</span>);
  };

  return (
    <SimpleForm>
      {/* TODO: restyle this part */}
      <Typography variant="h2" gutterBottom>
        Word:
        <ReferenceField label="Word" source="wordId" reference="words" >
          <TextField source="word" variant="h2" />
        </ReferenceField>
        &nbsp;
        <ReferenceField label="Is Catalan" source="wordId" reference="words">
          <IsCatalanField source="isCatalan" />
        </ReferenceField>
      </Typography>

      <TextInput source="_text" label="Translation" fullWidth validate={[required()]} />
      {type}
      {gender}
      {category}
      {examplesContent}
      <TextInput source="plural" fullWidth label="Masculine/mixed plural conjugation" />
      <TextInput source="fems" label="Feminine Conjugation" fullWidth />
      <TextInput source="femplural" label="Plural Feminine Conjugation" fullWidth />
      <TextInput source="catnote" label='Catalan Note' fullWidth multiline={true} helperText="Any helpful notes in Catalan" />
      <TextInput source="engnote" label='English Note' fullWidth multiline={true} helperText="Any helpful notes in English" />
      <TextInput source="register" fullWidth helperText="Examples: 'colloquial', 'vulgar', 'formal'" />
      <TextInput source="local" fullWidth  helperText="Local variation, e.g. 'valencian'" />
      <TextInput source="catacro" label='Catalan Acronym' fullWidth helperText="Spelled out acronym, e.g. 'CAD' is 'Disseny Assistit per Ordinador'" />
      <TextInput source="engacro" label='English Acronym' fullWidth helperText="Spelled out acronym, e.g. 'CAD' is 'Computer Assisted Design'" />
      <BooleanInput source="engacro" label='Pronominal' fullWidth helperText="Is a pronoun" />
      <BooleanInput source="beforenoun" label='Used before a noun' fullWidth helperText="Adjective which is generally used before the noun in Catalan" />
      <TextInput source="synonyms" label='Synonyms' fullWidth helperText='Separated by commas, like "healthy, active, useful"' />
    </SimpleForm>
  );
}
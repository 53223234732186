import {
  BooleanInput,
  Button,
  CreateButton,
  DateInput,
  Edit,
  EditButton,
  FormTab,
  Link,
  NumberInput,
  TabbedForm,
  TextInput,
  ReferenceManyField,
  Datagrid,
  TextField,
  useRecordContext,
} from 'react-admin';
import { Word } from 'catalan-dictionary-common';

const CreateRelatedTranslationButton = () => {
  const word: Word = useRecordContext();
  return (
    <Button
      component={Link}
      to={{
        pathname: '/translations/create',
        state: { record: { wordId: word.id } },
      }}>
      <span>Create</span>
    </Button>
  );
};

export function WordEdit() {
  const castNullToUndefined = (val: any) => {
    return (val == null) ? '' : val;
  };
  return (
    <Edit redirect={false}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput disabled source="id" />
          <TextInput source="word" />
          <TextInput source="word_canonical" />
          <BooleanInput source="isCatalan" />
          <NumberInput source="frequency" defaultValue={0} />

          {/* NOTE: for these three, need to explicitly cast `null` => `''` to avoid errors in the form */}
          <TextInput source="image_data" format={castNullToUndefined} />
          <TextInput source="audio_data" format={castNullToUndefined} />

          <DateInput disabled source="created_at" />
          <DateInput disabled source="updated_at" />
        </FormTab>
        <FormTab label="Translations">
          <ReferenceManyField reference="translations" target="word_id">
            <Datagrid>
              <TextField source="_text" label="Translation" />
              <TextField source="type" />
              <TextField source="index" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <CreateRelatedTranslationButton />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};


import { Chip } from '@mui/material';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  List,
  NumberField,
  SearchInput,
  TextField,
  TopToolbar,
  UrlField,
} from 'react-admin';

const wordFilters = [
  <SearchInput source="q" alwaysOn />,
];

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const WordList = () => (
  <List filters={wordFilters} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="word" />
      <TextField source="word_canonical" />
      <BooleanField source="isCatalan" />
      {/* NOTE: don't show the `definitions` field, because it's handled by `Translations` */}
      <NumberField source="frequency" />
      <TextField source="image_data" />
      <UrlField source="audio_data" />
      <TextField source="conjugations" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);
import { Create } from 'react-admin';

import { TranslationForm, TranslationFormProps } from './TranslationForm';

export function TranslationCreate(props: TranslationFormProps) {
  return (
    <Create>
      <TranslationForm {...props} />
    </Create>
  );
}
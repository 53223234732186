import { Admin, Resource } from 'react-admin';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import { decodeJwt } from 'jose';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

import { WordCreate } from './resources/WordCreate';
import { WordEdit } from './resources/WordEdit';
import { WordList } from './resources/WordList';
import { TranslationCreate } from './resources/TranslationCreate';
import { TranslationEdit } from './resources/TranslationEdit';
import { TranslationList } from './resources/TranslationList';
import { APP_ROOT_URI } from './conf';
import LoginButton from './components/LoginButton';



const App = () => {
  const [hasEditWordPermission, setHasEditWordPermission] = useState(false);
  /**
   * Assuming user is logged into Auth0, fetch the access token and use it for
   * all API calls.
   */
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const httpClient = async (url: string, options: any = {}): Promise<any> => {
    const accessToken = await getAccessTokenSilently();
    options.headers = options.headers ? new Headers(options.headers) : new Headers({ Accept: 'application/json' });
    options.headers.append('Authorization', `Bearer ${accessToken}`);
    return fetchUtils.fetchJson(url, options);
  };
  const dataProvider = simpleRestProvider(APP_ROOT_URI, httpClient, 'X-Total-Count');

  /**
   * Once user is authenticated, check if they have the edit:words permission.
   */
  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        const data = decodeJwt(accessToken);
        const permissions: string[] = data.permissions as string[];
        setHasEditWordPermission(permissions && permissions.includes('edit:words'));
      })();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <LoginButton />
        </Box>
      </Container>
    );
  }

  if (!hasEditWordPermission) {
    return <div>You do not have permission to access this page.</div>;
  }

  return (
    <Admin dataProvider={dataProvider}>
      <Resource name="words" list={WordList} edit={WordEdit} create={WordCreate} />
      <Resource name="translations" list={TranslationList} edit={TranslationEdit} create={TranslationCreate} />
    </Admin>
  );
};

export default App;

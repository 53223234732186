import {
  Datagrid,
  List,
  TextField,
} from 'react-admin';

export const TranslationList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="type" />
      <TextField source="gender" />
      <TextField source="plural" />
      <TextField source="fems" label="Feminine Conjugation" />
      <TextField source="femplural" label="Plural Feminine Conjugation" />
      <TextField source="catnote" label='Catalan Note' />
      <TextField source="engnote" label='English Note' />
      <TextField source="register" />
      <TextField source="local" />
      <TextField source="catacro" label='Catalan Acronym' />
      <TextField source="engacro" label='English Acronym' />
    </Datagrid>
  </List>
);